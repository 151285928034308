<template>
  <v-app-bar dense flat app class="prd-app-bar" height="55">
    <v-row dense class="align-center">
      <v-col
        cols="auto"
        class="flex-shrink-1 flex-grow-0"
        v-if="!navDrawer || $vuetify.breakpoint.smAndDown"
      >
        <toggle-nav-btn @toggleMenu="toggleMenu"></toggle-nav-btn>
      </v-col>

      <v-col cols="9" md="10">
        <v-row no-gutters class="align-center">
          <v-col cols="auto">
            <the-breadcrumbs v-if="!$route.meta.hideBreadcrumb"></the-breadcrumbs>
          </v-col>
          <v-col cols="10">
            <the-heading v-if="$vuetify.breakpoint.smAndUp"></the-heading>
          </v-col>
          <v-col cols="12">
            <top-bar-tabs v-if="tabs.length" :tabs="tabs"></top-bar-tabs>
          </v-col>
        </v-row>
      </v-col>

      <v-spacer></v-spacer>

      <v-col cols="auto" class="d-flex justify-end">
        <top-bar-notifications></top-bar-notifications>
        <top-bar-calendar></top-bar-calendar>
        <top-bar-user></top-bar-user>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  components: {
    TopBarCalendar: () => import("@/layouts/intranet-tabs/components/TopBarCalendar"),
    TopBarUser: () => import("@/layouts/intranet-tabs/components/TopBarUser"),
    TopBarNotifications: () => import("@/layouts/intranet-tabs/components/TopBarNotifications"),
    ToggleNavBtn: () => import("@/layouts/intranet-tabs/components/ToggleNavBtn"),
    TheHeading: () => import("@/layouts/intranet-tabs/components/TopBarHeading"),
    TheBreadcrumbs: () => import("@/layouts/intranet-tabs/components/Breadcrumbs"),
    TopBarTabs: () => import("@/layouts/intranet-tabs/components/TopBarTabs"),
  },
  props: {
    navDrawer: { type: Boolean, required: true },
  },
  data: () => ({}),
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu", !this.navDrawer);
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    tabs() {
      return this.$route.meta.tabs || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.prd-app-bar {
  border-bottom: 1px solid lightgray;
}
</style>
